<template>
  <div class="p-grid p-justify-center">
    <div class="p-col-12 p-md-8">
      <div class="card p-fluid">
        <h5>Редактирование чеклиста</h5>
        <div class="p-field">
          <label>Выберите тип</label>
          <Dropdown
            v-model="selectedType.val"
            :options="checklistTypes"
            optionLabel="name"
            placeholder="Выберите тип"
            :class="!selectedType.isValid ? 'p-invalid' : ''"
          />
        </div>

        <div class="p-field">
          <label>Введите название</label>
          <InputText
            v-model="checklistName.val"
            :class="!checklistName.isValid ? 'p-invalid' : ''"
          />
        </div>

        <div class="p-field">
          <label>Введите стоимость нормо-часа (в рублях)</label>
          <InputNumber
            v-model="checklistNormalHourPrice.val"
            :class="!checklistNormalHourPrice.isValid ? 'p-invalid' : ''"
          />
        </div>

        <div>
          <Button label="Сохранить" @click="submitChecklist"></Button>
        </div>

        <h5>Элементы чеклиста</h5>
        <div class="p-field">
          <DataTable
            :value="checklist?.items"
            class="p-datatable-customers p-datatable-striped"
            dataKey="id"
            :rows="10"
            :rowHover="true"
            :autoLayout="true"
            :loading="isLoading"
          >
            <template #empty>Список чеклистов пуст</template>
            <template #loading>Загрузка...</template>
            <Column field="number" header="Номер" :sortable="true">
              <template #body="slotProps">
                {{ slotProps?.data?.number }}
              </template>
            </Column>
            <Column field="name" header="Название" :sortable="true">
              <template #body="slotProps">
                {{ slotProps?.data?.name }}
              </template>
            </Column>
            <Column field="type" header="Тип" :sortable="true">
              <template #body="slotProps">
                {{
                  getTypesDict.find((c) => c.value === slotProps?.data?.type)
                    .name
                }}
              </template>
            </Column>
            <Column field="delete">
              <template #body="slotProps">
                <Button
                  :disabled="editMode"
                  class="p-button-warning p-mr-3"
                  icon="pi pi-pencil"
                  @click="editChecklistItem(slotProps?.data)"
                />
                <Button
                  :disabled="editMode"
                  class="p-button-danger"
                  @click="deleteChecklistItem(slotProps?.data?.id)"
                  icon="pi pi-trash"
                />
              </template>
            </Column>
          </DataTable>
        </div>
        <div class="p-field p-d-flex">
          <InputNumber
            v-model="itemNumber.val"
            style="flex: 1"
            placeholder="Номер"
            class="p-mr-3"
            :class="!itemNumber.isValid ? 'p-invalid' : ''"
          />
          <InputText
            v-model="itemName.val"
            class="p-mr-3"
            style="flex: 4"
            placeholder="Название"
            :class="!itemName.isValid ? 'p-invalid' : ''"
          />
          <Dropdown
            v-model="itemType.val"
            :options="itemTypes"
            class="p-mr-3"
            optionLabel="name"
            style="flex: 4"
            placeholder="Тип"
            :class="!itemType.isValid ? 'p-invalid' : ''"
          />
          <Button
            v-if="!editMode"
            @click="submitNewItem"
            style="flex: 1"
            class="pi pi-plus"
          />
          <Button
            v-else
            @click="confirmEdit"
            style="flex: 1"
            class="pi pi-check"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";
import { CHECKLIST_ITEMS_TYPES } from "@/models/roles";

export default {
  name: "ChecklistEdit",
  data() {
    return {
      isLoading: false,
      isItemForValid: true,
      checklist: null,
      editMode: false,
      editingItem: null,
      selectedType: {
        val: null,
        isValid: true,
      },
      checklistName: {
        val: null,
        isValid: true,
      },
      checklistNormalHourPrice: {
        val: null,
        isValid: true,
      },
      itemTypes: null,
      itemNumber: {
        val: null,
        isValid: true,
      },
      itemName: {
        val: null,
        isValid: true,
      },
      itemType: {
        val: null,
        isValid: true,
      },
    };
  },
  mounted() {
    this.loadChecklist();
    this.getItemsTypes();
  },
  computed: {
    getTypesDict() {
      return CHECKLIST_ITEMS_TYPES;
    },
    checklistTypes() {
      return this.$store.getters["data/workTypes"];
    },
  },
  methods: {
    editChecklistItem(data) {
      this.editMode = true;
      this.editingItem = data;
      this.itemNumber.val = data.number;
      this.itemName.val = data.name;
      this.itemType.val = this.getTypesDict.find((c) => c.value === data?.type);
    },
    async confirmEdit() {
      this.validateItemForm();
      if (!this.isItemForValid) return;
      this.isLoading = true;

      try {
        let payload = {
          checklistTemplateId: this.checklist.id,
          id: this.editingItem.id,
          name: this.itemName.val,
          number: this.itemNumber.val,
          type: this.itemType.val.value,
        };
        await fetch(
          `${environment.apiUrl}/checklist_templates/items/${this.editingItem.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(payload),
          }
        );
      } catch (err) {
        console.log(err);
      }

      this.editMode = false;
      this.isLoading = false;
      this.itemType.val = null;
      this.itemName.val = null;
      this.itemNumber.val = null;
      await this.loadChecklist();
    },
    async deleteChecklistItem(id) {
      this.isLoading = false;

      try {
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates/items/${id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
            method: "DELETE",
          }
        );
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
      await this.loadChecklist();
    },
    async getItemsTypes() {
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates/items/types`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );
        this.itemTypes = await response.json();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    async submitNewItem() {
      this.validateItemForm();
      if (!this.isItemForValid) return;
      this.isLoading = true;

      try {
        let payload = {
          checklistTemplate: this.checklist,
          name: this.itemName.val,
          number: this.itemNumber.val,
          type: this.itemType.val.value,
        };
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates/items`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload),
          }
        );
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
      await this.loadChecklist();
    },
    async loadChecklist() {
      this.isLoading = true;

      try {
        let response = await fetch(
          `${environment.apiUrl}/checklist_templates/${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        response = await response.json();
        this.checklist = response;
        this.checklistName.val = response.name;
        this.checklistNormalHourPrice.val = response.normalHourPrice / 100;
        this.selectedType.val = this.checklistTypes.find(
          (c) => c.value === response.type
        );
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    validateForm() {
      this.formIsValid = true;
      this.checklistName.isValid = true;
      this.selectedType.isValid = true;
      this.checklistNormalHourPrice.isValid = true;

      if (!this.selectedType.val) {
        this.formIsValid = false;
        this.selectedType.isValid = false;
      }

      if (!this.checklistName.val) {
        this.formIsValid = false;
        this.checklistName.isValid = false;
      }

      if (this.checklistNormalHourPrice.val < 0) {
        this.formIsValid = false;
        this.checklistNormalHourPrice.isValid = false;
      }
    },
    validateItemForm() {
      this.isItemForValid = true;
      this.itemType.isValid = true;
      this.itemNumber.isValid = true;
      this.itemName.isValid = true;

      if (!this.itemType.val) {
        this.isItemForValid = false;
        this.itemType.isValid = false;
      }

      if (!this.itemNumber.val) {
        this.isItemForValid = false;
        this.itemNumber.isValid = false;
      }

      if (!this.itemName.val) {
        this.isItemForValid = false;
        this.itemName.isValid = false;
      }
    },
    async submitChecklist() {
      this.validateForm();
      if (!this.formIsValid) return;

      this.isLoading = true;

      try {
        const payload = {
          id: this.$route.params.id,
          name: this.checklistName.val,
          type: this.selectedType?.val?.value,
          normalHourPrice: this.checklistNormalHourPrice.val * 100,
        };
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates/${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(payload),
          }
        );
        await this.$router.push("/checklist_maker");
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
